import React from 'react';
import HomeSection from '../../sections/home/Home';
import MobilitySection from '../../sections/mobility/Mobility';
import WhyNomadSection from '../../sections/whynomad/WhyNomad';
import UnbiasedSection from '../../sections/unbiased/Unbiased';

const home =(props)=> {
    return (
        <React.Fragment>
            <HomeSection></HomeSection>
            <UnbiasedSection windowSize={props.windowSize} />
            <MobilitySection windowSize={props.windowSize}/>
            <WhyNomadSection windowSize={props.windowSize}/>
        </React.Fragment>
    );
}

export default home;