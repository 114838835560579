import React from 'react';
import TopBar from '../../ui/topbar/topbar';
import Navbar from '../../ui/navigation/NavBar';

const navigation = (props)=> {
    return (
        <React.Fragment>
            <div className="fixed-top">
                <TopBar></TopBar>
                <Navbar windowSize={ props.windowSize }></Navbar>
            </div>
        </React.Fragment>
        
    );
}

export default navigation;