import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as classes from './modal.module.css';
import ContactUs from './ContactUs';
import RequestDemo from './RequestDemo';

class NomadModal extends Component{

    onPhoneClicked =()=> {
        window.open('tel:619-313-4300', "_blank");
    }

    onEmailClicked =()=> {
        window.location.assign('mailto:info@nomaddirect.com' )
    }

    render() {
        let content;
        if ( this.props.type === "contact") {
            content = <ContactUs emailClicked={this.onEmailClicked} phoneClicked={this.onPhoneClicked} class={classes}></ContactUs>;
        } else {
            content = <RequestDemo></RequestDemo>;
        }

        return(
            <div>
            <Modal isOpen={this.props.open} toggle={this.props.toggle} className={classes.nomadModal} >
                <ModalHeader toggle={this.props.toggle} className={classes.nomadHeader} charCode="X"></ModalHeader>
                <ModalBody className={classes.nomadBody}>
                {content}
                </ModalBody>
            </Modal>
            </div>
        );
    }
    
}
export default NomadModal;