import React, {Component} from 'react';
import BrandImage from '../../../assets/images/NomadDirectLogo.png';
import NavLinksMobile from './NavLinksMobile';
import NavLinks from './NavLinks';

class Navbar extends Component {
    state = {
        navCollapsed: true
    }

    onToggleNavigation = () => {
        this.setState({ navCollapsed: !this.state.navCollapsed })
    }

    render() {
        const {navCollapsed} = this.state;
        
        const navigation = ( this.props.windowSize >= 1200 ) 
        ?   <NavLinks clicked={this.onToggleNavigation} />
        :   <NavLinksMobile clicked={this.onToggleNavigation} />
        
        return (
            <React.Fragment>
                <nav className="navbar navbar-expand-xl navbar-dark">
                    <a className="navbar-brand" href="/"><img className='brand-logo' src={BrandImage} alt='logo'/></a>
                    <button onClick={this.onToggleNavigation} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={(navCollapsed ? 'collapse' : '') + ' navbar-collapse'} id="navbarResponsive">
                        {navigation}
                    </div>
                </nav>
            </React.Fragment>
        )
    }
}

export default Navbar;



            
                