import React, { Component } from 'react';

class Testimonials extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeIndex: 0,
            milliseconds: 8000,
            testimonials :[
                {
                    testimonial: "\"Best portal in the industry, Easy to Use, I love it.\" I would say you have created an exceptional tool. Thank you. I cannot tell you how much we appreciate Nomad's contribution to elevating out industry to a whole new level!",
                    author: "Nomad Partner",
                    team: ""
                },
                {
                    testimonial: "Nomad's founder asked me to review their business plan before they launched. The model of unbiased selection was very innovative, and a market disrupter. When Nomad opened, we tested out the concept. Since then, we have been extremely pleased with their execution of unbiased options, superior rate variety, and their high service level. We have been thrilled using Nomad. They truly aggregated the temp housing industry",
                    author: "Nomad Client",
                    team: ""
                },
                {
                    testimonial: "I have been entering options in the Nomad portal all day and I must say… It is AMAZING! So user friendly! I have had no issues at all. Everything is so much easier & more efficient than the other platforms I enter options in all day. You guys really knocked it out of the park with this platform! I am seriously impressed!",
                    author: "Nomad Partner",
                    team: ""
                },
                {
                    testimonial: "Great platform, very user friendly, easy to navigate through, and easy to submit options. Maybe one day an invoice tab can be added so that companies would be able to see invoices have been received and status of payment for reservations, but other than that I think it's a great platform. ",
                    author: "Nomad Partner",
                    team: ""
                }
            ]
        }
    }

    componentDidMount() {
        this.timer = setInterval( this.tick, this.state.milliseconds );
    }

    componentWillUnmount() {
        clearInterval( this.timer );
    }

    tick = () => {
        const currentIndex = this.state.activeIndex;
        let index = currentIndex + 1;
        if ( index === this.state.testimonials.length ) {
            index = 0;
        }
        this.setState ({ activeIndex: index });
    }

    render () {
        const testimonial = this.state.testimonials[this.state.activeIndex]
        return(
            <React.Fragment>
                <section id="testimonials" className="testimonials">
                    <h1 className="testimonial-header">Testimonials</h1>
                    <div className="container">
                        <div className="row">
                            <div className="col w3-animate-opacity">
                            <h2 className="testimonial-subheader">{testimonial.testimonial}</h2>
                            <p className="testimonial-author">{testimonial.author} {testimonial.team}</p>
                            </div >
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Testimonials;