import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import EmailIcon from '../../../assets/images/contact_email_icon.png';
import PhoneIcon from '../../../assets/images/contact_phone_icon.png';

const ContactUs =(props)=> {
    return (
        <Container>
            <Row className="text-center">
                <Col>
                <img className='contact-image' src={PhoneIcon} alt="nomad direct website phone icon" />
                <h1 className={props.class.nomadHeadline}>Call us.</h1>
                <p className={props.class.nomadLink} onClick={props.phoneClicked} >(619) 313-4300</p>
                </Col>
                <Col onClick={props.emailClicked}>
                <img className='contact-image' src={EmailIcon} alt="nomad direct website email icon" />
                <h1 className={props.class.nomadHeadline}>Drop us a line.</h1>
                <p className={props.class.nomadLink}>info@nomadtemphousing.com</p>
                </Col>
            </Row>
        </Container>
    );
}
export default ContactUs;