
import EasySetUp from '../../assets/images/ipad_rounded_corners_700x290.png';
import NoBias from '../../assets/images/bias_rounded_corners.png';
import ReportingImage from '../../assets/images/ipad_reporting_rounded_corners.png';
import ClientSupportImage from '../../assets/images/client_support_rounded_corners.png';

const initialState = {
    housingCards: [
        { 
            title: "Easy Set-up",
            image: EasySetUp,
            content: "<p>Nomad, unlike all other platforms has built over 15,000 property profiles for all major markets and most remote markets. Chances are, most of your properties are already loaded. This makes getting onboard simple.</p><p>Once you have sent us a Housing Option from a certain property, you can easily save it for use the next time you wish to offer up that property. This is a major time-saver over other platforms.</p>",
        },
        { 
            title: "No Bias to Any Single Brand",
            image: NoBias,
            content: "<p>Nomad has no “premium listings” or “website display fees”. These add bias and are unfair. Your options display and are presented based on pure merit and meeting the needs, wants, and desires of the guest. Nomad curates the received options, and presents the best three to five offerings to the guest.</p>",
        },
        { 
            title: "Incredible Volume Reporting",
            image: ReportingImage,
            content: "<p>Over 1,000 data points are captured in Nomad Direct.  You can view your win rate, presentation rate, reasons for not being presented, and much more.  You can drill down by market, by property, or by neighborhood. This is real data that supports your analytics, and helps you win the rental!</p>"
        },
        { 
            title: "Easy Client Support",
            image: ClientSupportImage,
            content: "<p>Submit arrival instructions in our easy to use portal. Save submissions by property and unit type for future use.  Submit pre-arrival inspections/video/photos using our industry leading mobile app. Our service request system is easy to view and use - 24/7.</p>"
        },
    ]
}

const reducer = ( state = initialState , action ) => {
    switch( action.type ) {
        default :
            return state;
    }
}

export default reducer;