import React, { Component } from 'react';
import NomadModel from '../modal/Modal';

class topbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState( prevState => ({
            modal: !prevState.modal
        }));
    }

    onUserLoginClicked =()=> {
        window.open('https://my.nomaddirect.com/login', "_blank");
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex flex-row justify-content-end topbar">
                    <div className="contact-link" onClick={this.onUserLoginClicked}>USER LOGIN</div>
                    <div className="contact-link" onClick={this.toggle}>CONTACT US</div>
                </div>
                <NomadModel
                    open={this.state.modal}
                    toggle={this.toggle}
                    type="contact"
                />
            </React.Fragment>
        );
    }
}

export default topbar;
