import React from 'react';
import Hero from '../../../assets/images/hero.png';

const home =(props)=> {
    return (
        <section id="home" className="home">
                <img className="img-fluid" src={Hero} alt="nomad direct website hero element" />
        </section>
    );
}

export default home;