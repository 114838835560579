import React, { Component } from 'react';

class HousingCard extends Component {

    createMarkup =()=> { 
        return {__html: this.props.content}; 
    };

    getLayout=()=>{
        if ( this.props.windowSize <= 1200) {
            return  <React.Fragment>
                            <div className="col-xl-6 col-md-12 col-sm-12">
                                <img src={this.props.image} className="housing-card-image img-fluid" alt="housing card" />
                            </div>
                            <div className="col-xl-6 col-md-12 col-sm-12">
                                <h4 className="housing-card-title">{this.props.title}</h4>
                                <div className="housing-card-content" dangerouslySetInnerHTML={this.createMarkup()}></div>
                            </div>
                        </React.Fragment>
        } else {
            if ( this.props.index % 2 === 0 ){
                return <React.Fragment>
                            <div className="col-xl-6 col-md-12 col-sm-12">
                                <img src={this.props.image} className="housing-card-image img-fluid" alt="housing card" />
                            </div>
                            <div className="col-xl-6 col-md-12 col-sm-12">
                                <h4 className="housing-card-title">{this.props.title}</h4>
                                <div className="housing-card-content" dangerouslySetInnerHTML={this.createMarkup()}></div>
                            </div>
                        </React.Fragment>
            } else {
                return <React.Fragment>
                            <div className="col-xl-6 col-md-12 col-sm-12">
                                <h4 className="housing-card-title">{this.props.title}</h4>
                                <div className="housing-card-content" dangerouslySetInnerHTML={this.createMarkup()}></div>
                            </div>
                            <div className="col-xl-6 col-md-12 col-sm-12">
                                <img src={this.props.image} className="housing-card-image img-fluid"  alt="housing card" />
                            </div>
                        </React.Fragment>
            }
        }
    }

    render() {
        return(
            <React.Fragment>
                {this.getLayout()}
            </React.Fragment>
        );
    }
}

export default HousingCard;