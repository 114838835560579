import React, { Component } from 'react';
import HousingCard from '../../../components/ui/housingCard/HousingCard';
import {connect} from 'react-redux';

class housingContent extends Component {

    render() {

        let content = this.props.housingCards.map( (card, index ) =>{
            return <HousingCard
                key={index}
                index={index}
                title={card.title}
                image={card.image}
                content={card.content}
                windowSize={this.props.windowSize}
                />
        });

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        {content}
                    </div>
                </div>
                <div className="spacer"></div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        housingCards: state.housingReducer.housingCards,
    }
}

export default connect(mapStateToProps)( housingContent );