import React from 'react';
import * as classes from "./awardCard.module.css";

const AwardCard = (props)=> {
    return (
        <div className="card">
            <div className={classes.container} onClick={props.clicked}>
                <img src={props.image} alt="Avatar" className={classes.image}/>
                <div className={classes.middle}>
                    <div className={classes.header}>{props.title}</div>
                </div>
            </div>
        </div>
    );
}

export default AwardCard;
