import React from 'react';
import SuitCase from '../../../assets/images/nomad_suitcase.png';
import Plane from '../../../assets/images/nomad_plane_and_globe.png';
import Building from '../../../assets/images/nomad_building.png';

const whyNomad = (props) => {
    return(
        <section id="whynomad" className="whynomad">
            <div className="container-fluid">
                <h1 className="section-header">Why Nomad Direct ?</h1>
                <div className="card-group">
                    <div className="card border-0">
                        <div className="text-center">
                            <img className="icon-image" src={SuitCase} alt="nomad website sutcase icon"/>
                        </div>
                        <div className="card-body">
                            <h3 className="card-title-why">For Clients</h3>
                            <div className="center">
                                <ul>
                                    <li>Easy global sourcing</li>
                                    <li>No long-term contracts</li>
                                    <li>Lowers housing costs</li>
                                    <li>The lowest-cost platform on the market</li>
                                    <li>Easy implementation and system training</li>
                                    <li>Vast, real-time reporting</li>
                                    <li>Guaranteed by Nomad</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card border-0">
                        <div className="text-center">
                            <img className="icon-image" src={Plane} alt="nomad website globe icon" />
                        </div>
                        <div className="card-body">
                            <h3 className="card-title-why">For Mobility Consultants</h3>
                            <div className="center">
                                <ul>
                                    <li>Saves time and speeds sourcing</li>
                                    <li>Provides built-in market expertise</li>
                                    <li>Filters out weaker options</li>
                                    <li>Offers auction-based rates, best price at time of booking</li>
                                    <li>Delivers incredible option detail</li>
                                    <li>Presents arrival instruction quickly and accurately</li>
                                    <li>Fast arrival instruction delivery</li>
                                    <li>Highlights special needs</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card border-0">
                        <div className="text-center">
                            <img className="icon-image" src={Building} alt="nomad website building icon" />
                        </div>
                        <div className="card-body">
                            <h3 className="card-title-why">For Housing Suppliers</h3>
                            <div className="center">
                                <ul>
                                    <li>Level field of competition</li>
                                    <li>Unbiased option presentation</li>
                                    <li>Quick and efficient option delivery</li>
                                    <li>Ability to save and duplicate options</li>
                                    <li>Requires no property profile builds</li>
                                    <li>Non-existent network or placement fees</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default whyNomad;