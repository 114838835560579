import React from 'react';
import NavItem from './NavItem';

const NavLinks =(props)=> {
    return (
        <ul className="navbar-nav ml-auto">
        <NavItem link={"/home#unbiased"} clicked={props.clicked}>UNBIASED<br/>FEATURES</NavItem>
        <NavItem link={"/home#mobility"} clicked={props.clicked}>BUILT FOR<br/>MOBILITY</NavItem>
        <NavItem link={"/About"} clicked={props.clicked}>ABOUT<br/>US</NavItem>
        <NavItem link={"/housing"} clicked={props.clicked}>HOUSING<br/>SUPPLIERS</NavItem>
        <NavItem link={"/home#whynomad"}clicked={props.clicked}>WHY NOMAD<br/>DIRECT ?</NavItem>
        <a href="https://nomadtemphousing.com/" className="no-padding" target="_blank" rel="noopener noreferrer" >NOMAD<br/>FULL SERVICE</a>
    </ul>
    );
}
export default NavLinks;