import React from 'react';

const subheader =(props)=> {
    return(
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-xl-12 col-lg-12 col-md-12">
                        <h3 className="subheader-text">{props.text}</h3>
                    </div>
                </div>
            </div>
        </React.Fragment> 
    );
}
export default subheader;