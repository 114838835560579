import React from 'react';
import NavItem from './NavItem';

const navLinksMobile =(props)=> {
    return(
        <ul className="navbar-nav ml-auto">
                <NavItem link={"/home#unbiased"} clicked={props.clicked}>UNBIASED FEATURES</NavItem>
                <NavItem link={"/home#mobility"} clicked={props.clicked}>BUILT FOR MOBILITY</NavItem>
                <NavItem link={"/About"} clicked={props.clicked}>ABOUT US</NavItem>
                <NavItem link={"/housing"} clicked={props.clicked}>HOUSING SUPPLIERS</NavItem>
                <NavItem link={"/home#whynomad"} clicked={props.clicked}>WHY NOMAD DIRECT ?</NavItem>
                <a href="https://nomadtemphousing.com/" className="mobile-padding" target="_blank" rel="noopener noreferrer" >NOMAD FULL SERVICE</a>
            </ul>
    );
}
export default navLinksMobile;