import { createStore, combineReducers } from 'redux';
import awardsReducer from './Reducers/AwardsReducer';
import newsReducer from './Reducers/NewsReducer';
import housingReducer from './Reducers/HousingReducer';

const rootReducer = combineReducers({
    // ...your other reducers here
    // you have to pass formReducer under 'form' key,
    // for custom keys look up the docs for 'getFormState'
    awardsReducer: awardsReducer,
    newsReducer: newsReducer,
    housingReducer
  })
export const store = createStore(rootReducer);
