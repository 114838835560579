import React from 'react';
import Header from '../../../components/ui/header/Header';
import AboutNomadCard from '../../sections/about/About';
import Team from '../../sections/team/Team';
import Testimonials from '../../../components/ui/testimonials/Testimonials';
import Awards from '../../sections/awards/Awards';
import News from '../../../containers/sections/news/News';


const About =(props)=> {
    return (
        <React.Fragment>
            <Header title="About Us"></Header>
            <AboutNomadCard windowSize={props.windowSize}></AboutNomadCard>
            <News windowSize={props.windowSize}></News>
            <Awards></Awards>
            <Team></Team>
            <Testimonials></Testimonials>
        </React.Fragment>
    );
}

export default About;