import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth"
    }); 
} 


const navItem = (props) => {
    const pageURI = window.location.pathname+window.location.search
    const liClassName = (props.path === pageURI) ? "nav-item active" : "nav-item";

    return (
        <li className={liClassName}>
            <Link 
                smooth to={props.link}
                exact={props.exact}
                className="nav-link"
                onClick={props.clicked}
                scroll={el => scrollWithOffset(el, 160)}>
                {props.children}
            </Link>
        </li>
    );
};

export default navItem;