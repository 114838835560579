import React, { Component } from 'react';

class NewsArticle extends Component {

    //passes in the string and the number of characters
    truncate =( str, n )=> {
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    }

    render() {
        return( 
            <div className="col-xs-12 col-md-12 col-xl-6" onClick={this.props.click}>
                <div className="news-card">
                    <p className="news-date">{this.props.date}</p>
                    <h4 className="news-title">{this.props.title}</h4>
                    <div className="news-content">
                        <p>
                            {this.truncate(this.props.content, 175)}
                        </p>
                    </div>
                </div>
                
            </div>
        );
    }
}
export default NewsArticle;