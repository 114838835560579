import React, { Component } from 'react';
import { Container , Row, Col } from 'reactstrap';
import {connect} from 'react-redux';

class Article extends Component {
    createMarkup =()=> { 
        return {__html: this.props.article.content}; 
    };
    
    render() {

        return (
            <section id="articleSection" className="article">
                <Container>
                    <Row>
                        <Col>
                            <article className="">
                            <h1 className="article-title">{this.props.article.title}</h1>
                                <div className="">
                                    <div className="article-content" dangerouslySetInnerHTML={this.createMarkup()}>
                                    </div>
                                </div>
                            </article>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
    
}

const mapStateToProps = state => {
    return {
        article: state.newsReducer.articles.find(x => x.id === state.newsReducer.selectedArticle),
    }
}

export default connect(mapStateToProps)(Article);