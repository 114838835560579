import React, { Component } from 'react';
import {connect} from 'react-redux';

class Award extends Component {

    createMarkup =()=> { 
        return {__html: this.props.award.content}; 
    };
    
    render() {

        return (
            <section id="awardSection" className="award-section">
                <div className="container">
                    <div className="row">
                    <div className="col">
                            <article>
                            <h1 className="award-title">{this.props.award.title}</h1>
                                <div className="clearfix p-2">
                                    <figure className="figure float-left p-4">
                                        <img src={this.props.award.image} className="figure-img img-fluid rounded" alt={this.props.award.title}/>
                                            <figcaption className="figure-caption text-center">{this.props.award.date}</figcaption>
                                    </figure>
                                    <div className="award-content" dangerouslySetInnerHTML={this.createMarkup()}>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        //award: state.awardsReducer.awards[state.awardsReducer.selectedAward],
        award: state.awardsReducer.awards.find(x => x.id === state.awardsReducer.selectedAward),
    }
}

export default connect(mapStateToProps)(Award);