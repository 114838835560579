import * as actionTypes from '../Actions/actions';

const initialState = {
    awards: [
        { 
            id: 19,
            header: "28th Annual Nationwide Relocating Employee Survey ",
            title: "Nomad Temporary Housing Achieves Highest Average Survey Scores in the 28th Annual Nationwide Relocating Employee Survey ",
            date: "Nov. 3, 2020",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/Nomad Award Logo.png",
            content: `<p>Our 11th #1 Ranking with Trippel Surveys!</p>
            <p>Trippel Survey & Research, LLC recently completed its 28th annual survey, obtaining evaluations from relocating employees regarding their 
            level of satisfaction with temporary housing service providers.</p>
            <p>Nomad Temporary Housing achieved the highest average survey score, ahead of all global, national, and regional temporary housing brands. 
            This is the 11th time Nomad has had the highest average score or net satisfaction score, 
            in either of the annual Trippel surveys. These include responses from both relocation managers and relocating employees.</p>`
        },
        { 
            id: 18,
            header: "BGRS Supplier of the Year Award",
            title: "BGRS Honors Nomad Temporary Housing with Supplier of the Year Award",
            date: "Nov. 3, 2020",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/BGRS 2020 Service.png",
            content: `<p>At their recent virtual Forum, BGRS presented Nomad with the prestigious Supplier of the Year Award for 2020.  
            Selected from a field of over 1900 supplier partners from a variety of relocation services, Nomad won based on several 
            key performance indicators.  These included high ratings in customer service, quick response time on a global basis, 
            consistent service delivery over thousands of rentals and amazing cost savings realized on behalf of BGRS clients.  
            In addition, Nomad's subject matter expertise in technology was cited as a driving factor, paving the way to establish 
            a tech-first, global partnership solution for temporary housing for BGRS.</p>
            <p>Considered an essential business, Nomad Temporary Housing has continued to deliver the high level of 
            service their clients expect, even during a pandemic.  
            Traci Morris, BGRS Chief Executive Officer, notes 
            "Nomad has demonstrated the true meaning of partnership by quickly reacting to the significant Covid 
            related changes and disruptions by ensuring BGRS clients and customers experienced minimal impact, 
            throughout the world, under very difficult circumstances. As some of their competitors exited the market, 
            they immediately assumed responsibility for active customer files and quickly delivered critical information 
            and exceptional service."  The business relationship between BGRS and Nomad goes back ten years, 
            and continues to grow stronger each year, fueled by new innovation on the technology front, 
            and expansion of products and services in new locations.</p>`
        },
        { 
            id: 19,
            header: "Aires Circle of Excellence Award",
            title: "NOMAD honored with circle of excellence award from Aires",
            date: "2020",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/Nomad Award Logo.png",
            content: `<p>Nomad Temporary Housing is delighted to announce they have received the Circle of Excellence Award from Aires Relocation. 
            This honor was bestowed by Aires for Nomad's provision of 
            superior service to the Aires client base, and for achieving top level service delivery scores.</p>
            <p>Considered an essential business, Nomad Temporary Housing has continued to deliver the highest level 
            temporary accommodations service experience in the industry, even during a pandemic year like 2020. 
            The Aires team, led by Joleen Lauffer and Brad Stevenson, state, 
            "We know it is not an easy feat! Your superior results help us to ensure overall client 
            satisfaction. We wanted to take a moment and acknowledge your accomplishment. Well done!"  
            Nomad has been providing temporary housing solutions to Aires for more than ten years.</p>`
        },
        { 
            id: 17,
            header: "2018 Partner in Quality Award from WHR Group",
            title: "Nomad Temporary Housing Receives 2018 Partner in Quality Award from WHR Group",
            date: "May 10, 2019",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/whr_group_2018.png",
            content: "<p>Nomad Temporary Housing is pleased to announce its recognition as a 2018 Partner in Quality by WHR Group, Inc.  Celebrating their 25th anniversary in 2019, WHR Group continues to be the innovator in the relocation space known for excellent service and concrete results for clients and for their top talent.</p><p>In granting these awards, WHR Group evaluates its Global Partner Network for partners that have exceeded commitments to customer satisfaction, supply chain management, service excellence, and overall cost management in the employee relocation sector. This past year was an exceptional one for Nomad.</p><p>\"Nomad is thrilled to be recognized by WHR for outstanding global service delivery of temporary housing,\" says Karl Thuge, EVP and co-owner of Nomad.</p>"
        },
        { 
            id: 0,
            header: "2019 Gold Service Award from RELO Direct",
            title: "Nomad Temporary Housing wins 2019 Gold Service Award from RELO Direct!",
            date: "March 26, 2019",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/Relo_Direct_Award.png",
            content: "<p>\"Nomad Temporary Housing is a long-term partner of more than eight years that consistently delivers a service experience and quality global accommodations that meet and exceed the expectations of our clients and their relocating employees,\" said Oliver Dubester, Director, Client Support Services for RELO Direct®, Inc. \"Nomad, through their commitment of providing the superior service of one centralized company, supports RELO Direct's mission of helping people move with confidence, security, and appreciation for their employer and we are grateful for their partnership.\"</p><p>Nomad was thrilled to win this award for the second time. \"We are honoured to support the global service delivery of RELO Direct, and their valued clients,\" said Gavan James, CEO of Nomad. \"This award reflects the daily dedication and effort of our front line associates, as well as the hard work of our partner network.\" This is Nomad's 27th major service award in the last five years, making Nomad one of the leading service providers in the temporary housing field in the world.</p>"
        },
        { 
            id: 1,
            header: "NEI Service Exceeding Expectations Award",
            title: "Nomad Temporary Housing, Inc. Wins NEI Service Exceeding Expectations Award",
            date: "April 12, 2018",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2016_NEI_outstanding_service.png",
            content: "<p>Omaha, Nebraska ─ Nomad Temporary Housing, Inc. is pleased to announce that it has earned the 2018 NEI Service Exceeding Expectations award. The NEI Service Exceeding Expectations Award is presented to Service Partners by industry segment and is based on the highest percentile scores, as captured in NEI scorecard processes.</p><p>“The goals of our awards program are to recognize and thank those service partners who are truly committed to delivering on NEI’s mission of providing Service Exceeding Expectations to our clients’ relocating families,” said Al Blumenberg, NEI SVP of Global Client Development and Service Partner Relations. “Our program continues to grow and we are very excited to be able to present 31 awards tonight as we celebrate our sixth year of the NEI GPA Summit.”<p>"
        },
        { 
            id: 2,
            header: "Individual of the Year Award",
            title: "Gavan James Receives Individual of the Year Award from the Corporate Housing Providers' Association",
            date: "April 12, 2018",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2018_individual_of_the_year.png",
            content: "<p>Nomad Temporary Housing President and CEO, Gavan James, has been awarded the Individual of the Year (300 units or greater) by the Corporate Housing Providers' Association (CHPA). CHPA is the professional trade association exclusively dedicated to supporting corporate housing providers around the world. Every year the industry gathers for its annual conference and awards ceremony, where the recipients of the Tower of Excellence are recognized.”<p>"
        },
        { 
            id: 3,
            header: "2017 Partner in Quality Award from WHR Group",
            title: "Nomad Temporary Housing Receives 2017 Partner in Quality Award from WHR Group",
            date: "March 6, 2018",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2017_WHR.png",
            content: "<p>Nomad Temporary Housing is pleased to announce its recognition as a 2017 Partner in Quality by WHR Group Inc. WHR Group helps companies relocate their employees to new opportunities all over the world. With over 20 years' history of offering innovative solutions for clients, and concrete results for their top talent, WHR Group bases its success on creating long-term partnerships with knowledgeable, worldwide experts and strives to recognize these experts annually.</p><p>In granting these awards, WHR Group evaluates its Global Partner Network for partners that have exceeded commitments to customer satisfaction, supply chain management, service excellence, and overall cost management in the employee relocation sector. This past year was an exceptional one for Nomad Temporary Housing.<p><p>Karl Thuge, Partner and EVP at Nomad states, \"This award honors our frontline employees in reservations and guest services, and their long-standing commitment to service excellence. It acknowledges their efforts every day to make each guest feel like Nomad cares, the client company cares, and of course, that WHR Group cares about every aspect of the employee's relocation or assignment. Our team is humbled to serve, and very much appreciates the recognition!\"</p>"
        },
        { 
            id: 4,
            header: "2017 FEM Americas EMMAs Corporate Housing Provider of the Year",
            title: "Nomad Temporary Housing wins 2017 FEM Americas EMMAs Corporate Housing Provider of the Year — for the Second Year in a Row",
            date: "May 9, 2017",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2017_Emmas.png",
            content: "<p>The FEM EMMAs are the premier awards for the global mobility and HR industry and following a highly successful two-day Summit, 200+ key figures, leaders and companies gathered to celebrate the brightest and best in the Americas.</p><p>Judged against a strict set of criteria, the competition for this award was fierce, with nine shortlisted finalists.</p><p>The judges commented on Nomad Temporary Housing's entry:<p><p>\"Nomad offer a very innovative concept. Their suite of services and innovation drives results that provide direct benefits to their clients.\"</p><p>\"We are thrilled to be honoured with this prestigious award for the second year in a row,\" says Gavan James, CEO of Nomad. \"We are grateful to our fantastic Nomad staff, quality housing partners around the globe, and our incredibly loyal clients that have made this possible,\" James adds.</p><p>Earlier this year, Nomad Temporary Housing received a Gold Service Excellence Award from RELO Direct, and scored the top rating in the 14th Annual, Relocation Managers' Survey© conducted by Trippel Survey & Research, LLC. Nomad is ranked #1 in Overall Satisfaction, Net Satisfaction, Loyalty, Innovation and Partnership, and Willingness to Recommend. Nomad has also received this top ranking, two years in a row. Further, Nomad has also received numerous other service awards from our valued clients in the last twelve months.</p>"
        },
        { 
            id: 5,
            header: "EMMA Award Corporate Housing Provider of the Year",
            title: "Forum for Expatriate Management Honors Nomad Temporary Housing as EMMA Award Winner For Corporate Housing Provider of the Year",
            date: "Nov. 28, 2017",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2017_Emmas.png",
            content: "<p>The Forum for Expatriate Management (FEM) announced Nomad Temporary Housing, the premier global provider of temporary housing, as the 2017 Expatriate Management & Mobility Award winner for the EMEA region in the Corporate Housing Provider Category. Nomad also won in this category in May 2017 for the Americas region.</p><p>Karl Thuge, Partner and EVP at Nomad states, \"Nomad is humbled to receive this recognition from our global peers in the industry. While our service model was built upon cutting edge technology, we continue to invest in our 'high touch' customer approach. This award is confirmation of what so many of us in relocation understand; high tech and high touch are not mutually exclusive.\"</p><p>The FEM EMMAs are the premier awards for the global mobility and HR industry around the world, bringing together global mobility's brightest and best in EMEA for this specific event. All award submissions are judged by a panel of independent experts against a set of strict criteria, who noted that the standard of entries was very high yet again for 2017. One judge remarked about Nomad's aggregator model \"A really unique market proposition, and one which will save money for clients via their mini auction methodology…..Hugely impressed by innovation-focused approach\". The award follows Nomad's massive growth on a global scale, with clients embracing the aggregator model, and recognizing the abilities it has in the worldwide marketplace.</p>"
        },
        { 
            id: 6,
            header: "Gold Service Excellence Award From RELO Direct",
            title: "Nomad Temporary Housing Wins Gold Service Excellence Award From RELO Direct",
            date: "Nov. 28, 2016",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2016_Relo_Direct_service_excellence_gold.png",
            content: "<p>RELO Direct, at its recent 7th Annual Corporate Forum Event, honored Nomad Temporary Housing with a Gold Service Excellence Award. Nomad has provided temporary accommodations for RELO Direct and its valued clients for more than six years in locations all over the globe.</p><p>\"At the core of everything RELO Direct® does is a commitment to meeting and exceeding the expectations of our clients and their relocating employees,\" said Oliver Dubester, Director, Global Supply Chain Management. \"In order to achieve that, we must partner with the best supplier partners available since they act as an extension of our own organization. Throughout our relationship, Nomad Temporary Housing has shared this vision for service excellence and we are grateful to be aligned with them.\"</p>"
        },
        { 
            id: 7,
            header: "Platinum level service award from Brookfield",
            title: "Nomad won the Platinum level service award from Brookfield at their supplier forum in Washington DC!!",
            date: "Nov. 1, 2016",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2016_BGRS_Platinum_award.png",
            content: "<p>Nomad Temporary Housing was awarded the 2016 Platinum Premier Service Award at the recent Brookfield Global Relocation Supplier Forum in Washington D.C. Nomad was one of eleven global suppliers to be honored at the platinum service level, and was the only organization in the temporary living service line to earn such recognition. Brookfield notes the following: \"With an ongoing focus on total commitment to quality, Brookfield has continued to collaborate with our supplier partners to enhance performance management and innovation, optimize mobility spend and mitigate risk. Congratulations to our 2016 award winners who have gone above and beyond to demonstrate their commitment to excellence.\"</p>"
        },
        { 
            id: 8,
            header: "Service Exceeding Expectations Award by NEI Global Relocation",
            title: "Nomad Temporary Housing Awarded Service Exceeding Expectations Award by NEI Global Relocation!",
            date: "Nov. 1, 2016",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2016_NEI_outstanding_service.png",
            content: "<p>Nomad Temporary Housing was awarded the 2016 Service Exceeding Excellence Award at the recent NEI Global Relocation Partner Alliance Summit in Omaha, NE. Hundreds of global partners gathered for educational sessions and to advance strategic partnerships. Nomad was one of nine global suppliers to receive this prestigious service award. “Our congratulations to Nomad Temporary Housing for receiving the NEI Service Exceeding Expectations Award for Temporary Living Accommodations in the large company category,” said NEI CEO, Randy Wilson. “The Service Exceeding Expectations Awards are presented to those companies with the highest evaluation scores over the past year. Nomad founder and CEO, Gavan James, served on our inaugural Global Partner Alliance Board of Directors from 2012-2014 and we sincerely appreciate the leadership skills and insights that he brought to that position. He and his company reflect the high-quality standards that we search for in all of our Service Partners and for that, we thank you on behalf of NEI, our clients and their relocating employees.”</p>"
        },
        { 
            id: 9,
            header: "Corporate Housing Company of the Year",
            title: "Nomad Temporary Housing named Corporate Housing Company of the Year!",
            date: "May 11, 2016",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2016_Emmas.png",
            content: "<p>Nomad Temporary Housing was awarded the 2016 Corporate Housing Company of the Year at the recent \"Emma Awards\" held in Philadelphia, Pennsylvania. The Forum for Expatriate Management (FEM) holds the Expatriate Management and Mobility Awards annually.</p><p>\"We are thrilled to win a prestigious Emma Award,\" says Gavan James, founder, president and chief executive officer of Nomad. \"This is another affirmation of Nomad's superior service, and unique unbiased model. We would like to thank our incredible staff and over 700 quality worldwide housing brands that contribute to our industry leading results.\"</p>"
        },
        { 
            id: 10,
            header: "Global Partner of the Year by Global Mobility Solutions",
            title: "Nomad Temporary Housing Awarded Global Partner of the Year by Global Mobility Solutions",
            date: "March 1, 2016",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2016_GMS_partner_of_the_year.png",
            content: "<p>Nomad Temporary Housing was awarded the 2015 Global Partner of the Year at the recent Global Mobility Solutions Supplier Forum in Scottsdale, AZ. Hundreds of suppliers gathered for educational sessions and to celebrate another amazing year serving GMS. The true collaboration between Nomad and GMS has been integral to the success of the partnership. Says John Fernandez, Vice President, Global Services, \"Since bringing Nomad into the Network, we have experienced record breaking conversions and satisfaction. Nomad's commitment to excellence is the perfect complement to our model. Thanks to all at the Nomad Team for a record breaking year.\"</p>"
        },
        { 
            id: 11,
            header: "Superior Quality Service Award From Graebel Relocation Services Worldwide",
            title: "Nomad Temporary Housing Wins Superior Quality Service Award From Graebel Relocation Services Worldwide!",
            date: "November 2, 2015",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2015_Graebel_superior_quality.png",
            content: "<p>At its recent 15th Annual Graebel Relocation Alliance Conference, Graebel honored Nomad Temporary Housing with the award for Superior Quality Achievement as chosen by guest/transferee. Nomad provides temporary accommodations for Graebel and its valued clients in locations all over the globe.</p>"
        },
        { 
            id: 12,
            header: "Ranked #422, Inc. Magazine",
            title: "Nomad Temporary Housing Ranked #422, Inc. Magazine",
            date: "November 2, 2015",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2015_Inc500.png",
            content: "<p>Nomad Temporary Housing is proud to announce Inc Magazine listed Nomad Temporary Housing #422 on its 34th annual Inc 5000, an exclusive ranking of the nation's fastest-growing companies. The list represents the most comprehensive look at the most important segment of the economy—America's independent entrepreneurs. Companies such as Yelp, Pandora, Timberland, Dell, Domino's Pizza, LinkedIn, Zillow, and many other well-known names gained early exposure as members of the Inc. 5000.</p>"
        },
        { 
            id: 13,
            header: "Brookfield Gold Premier Service Award",
            title: "Nomad Wins Brookfield Gold Premier Service Award",
            date: "November 2, 2015",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2015_BGRS_Gold_award.png",
            content: "<p>Nomad Temporary Housing is the proud recipient of the Gold Premier Service Award for Temporary Living from Brookfield Global Relocation Services. Out of more than 2,800 global supply chain partners, Nomad was the only company in the field of temporary living to be singled out for this honor. A strategic partner for Brookfield for the past three years, Nomad has consistently maintained extremely high service scores throughout their business relationship and through very high volume time periods.</p><p>Cindy Salter, Executive Vice President, Brookfield, states, \"Critically important is their cost and margin transparency. Thank you very much, Nomad!\"</p>"
        },
        { 
            id: 14,
            header: "Most Creative Marketing",
            title: "Most Creative Marketing",
            date: "November 2, 2015",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2012_most_creative_marketing.png",
            content: "<p>At the 2012 Tower of Excellence Awards event for the Corporate Housing Provider’s Association, Nomad Temporary Housing was one of two firms that won the coveted \"Most Creative Marketing\" award. Nomad earned the award due to its revolutionary, unbiased temporary apartment model that combines the unbiased choice of 74,000 temporary apartments with the full service of a single company. Further consideration for the award was given for Nomad’s clean website design, innovative option delivery presentations, and overall sales success.</p><p>Gavan James, Nomad Temporary Housing Founder and Chief Executive Officer, said “This award is the result of a combined effort of the Nomad team, Jacob Tyler Creative Group of San Diego, CA, and numerous large users of temporary housing who contributed to the formation of our business model. After our first year of business, the marketing efforts of Nomad resulted in capturing the business of over 25 international relocation management companies, and over 300 Fortune 2000 clients.\"</p><p>Heather James, Nomad Partner and Vice President of Business Development, was in attendance with Mr. James to receive this award. “It was thrilling to be recognized and exciting to win,” adds Heather. “Our customers are benefitting every day from our delivery model of unbiased choices, which have aggregated the industry.” Karl Thuge, Nomad’s third Partner and Executive Vice President, adds “Nomad has created a new way for clients to gain access to a fragmented industry. We are honored to win.\"</p>"
        },
        { 
            id: 15,
            header: "Person of the Year",
            title: "Person of the Year",
            date: "November 2, 2002",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/2002_gavan_james.png",
            content: "<p>Gavan James, Nomad Temporary Housing’s founder, president and CEO was tremendously honored to be the recipient of the very first “Person of the Year” award from the Corporate Housing Provider’s Association (CHPA) in 2003.</p><p>Gavan received the award at CHPA’s annual international conference in recognition for his contribution and leadership in developing the temporary corporate apartment industry into the $2 billion hospitality segment it has become today. In addition to founding Nomad, Gavan was a long term senior executive managing the worldwide operations of Oakwood, a global leader in the temporary housing industry. Gavan also was recognized as a founding board member of CHPA, past president, regular speaker, and contributor of the industry’s trade organization.</p>"
        },
        { 
            id: 16,
            header: "President Recognition",
            title: "President Recognition",
            date: "November 2, 2002",
            image: "https://s3-us-west-2.amazonaws.com/nomad-public-objects/NomadDirectWebsite/Awards/1998_AIHI_Gavan_James.png",
            content: "<p>Nomad Temporary Housing encourages all associates to serve in our industry and community. Our founder, Gavan James, has always led by example, and served two consecutive terms as president of CHPA, which was named the Association of Interim Housing Providers “in the early days” of the organization. Gavan was honored to receive this award and recognition at CHPA’s annual international conference.</p>"
        }
    ],
    selectedAward: 0,
    currentStartIndex: 0,
    collapse: false,

}

const reducer = ( state = initialState , action ) => {
    switch( action.type ) {
        case actionTypes.SELECT_AWARD:
            return {
                ...state,
                selectedAward: action.id
            }
        case actionTypes.COLLAPSE_AWARDS:
            return {
                ...state,
                collapse: action.collapse
            }
        default :
            return state;
    }
}

export default reducer;