import React, { Component } from 'react';
import { Container, Col, Form, FormGroup, Input, Button, Label, Alert } from 'reactstrap';
import axios from 'axios';
import * as classes from './requestDemo.module.css';

class RequestDemo extends Component{

    constructor(props){
        super(props);

        this.state = { 
            email: {
                name: '',
                company: '',
                title: '',
                email: '',
                comments: ''
            },
            showAlert: false,
            alertMessage: '',
            alertClass: ''
        }
    }

    handleSubmit =(event)=> { 
        event.preventDefault();
        const baseURL = process.env.REACT_APP_API_URL;

        console.log("baseURL", baseURL);
        axios.post(`${baseURL}request_demo`, this.state.email )
          .then((response) => {
            let message = 'We have sent your message along . We will contact you shortly.';
            let aClass = 'success';
            this.setState({ showAlert: true, alertMessage: message, alertClass: aClass });
        })
          .catch( (error) => {
            let message = 'We can not send your message at this time';
            let aClass = 'danger';
            this.setState({ showAlert: true, alertMessage: message, alertClass: aClass });
        })
    }
    
    handleNameChange =(event)=> {
        let email = {...this.state.email};
        email['name'] = event.target.value;
        this.setState({ email: email});
    }

    handleTitleChange =(event)=> {
        let email = {...this.state.email};
        email['title'] = event.target.value;
        this.setState({ email: email });
    }

    handleCompanyNameChange =(event)=> {
        let email = {...this.state.email};
        email['company'] = event.target.value;
        this.setState({ email: email });
    }

    handleEmailChange =(event)=> {
        let newemail = {...this.state.email};
        newemail['email'] = event.target.value;
        this.setState({ email: newemail});
    }

    handleAddressChange =(event)=> {
        let email = {...this.state.email};
        email['address'] = event.target.value;
        this.setState({ email: email });
    }

    handleCommentsChange =(event)=> {
        let email = {...this.state.email};
        email['comments'] = event.target.value;
        this.setState({ email: email });
    }

    render() {

        const alert = ( this.state.showAlert ) ? <Alert color={this.state.alertClass}>{this.state.alertMessage}</Alert> : '';

        return (
            <section id={classes.requestDemo}>
                <Container className={classes.requestDemoContainer}>
                    <h1 className="p-3">Request A Demo</h1>
                    {alert}
                    <Form className={classes.requestForm} onSubmit={(e) => this.handleSubmit(e)}>
                        <FormGroup row>
                            <Label for="name" sm={2}>Name</Label>
                            <Col sm={10}>
                                <Input type="text" name="name" id="name" onChange = {this.handleNameChange} value={this.state.email.name}placeholder="Please enter your name" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="company" sm={2}>Company</Label>
                            <Col sm={10}>
                                <Input type="text" name="company" id="company" onChange = {this.handleCompanyNameChange} value={this.state.email.company} placeholder="Please enter your company" />
                            </Col>
                        </FormGroup>
                       <FormGroup row>
                            <Label for="title" sm={2}>Title</Label>
                            <Col sm={10}>
                                <Input type="text" name="title" id="title" onChange = {this.handleTitleChange} value={this.state.email.title} placeholder="Please enter your title" />
                            </Col> 
                        </FormGroup>
                        <FormGroup row>
                            <Label for="title" sm={2}>Email</Label>
                            <Col sm={10}>
                                <Input type="email" onChange = {this.handleEmailChange} value={this.state.email.email} placeholder="Please enter your email" />
                            </Col> 
                        </FormGroup>
                        <FormGroup row>
                            <Label for="comments" sm={2}>Comments</Label>
                            <Col sm={10}>
                                <Input type="textarea" name="comments" id="comments" onChange = {this.handleCommentsChange} value={this.state.email.comments} placeholder="Please enter your message" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                        <Col sm={{ size: 10, offset: 2 }}>
                            <Button className={classes.formButton}>Submit</Button>
                        </Col>
                        </FormGroup>
                    </Form>
                </Container>
            </section>
            
        );
    }
}

export default RequestDemo;