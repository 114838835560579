import React from 'react';

const mobility = (props) => {
    return(
        <section id='mobility' className="mobility">
            <div className="container-fluid">
                <h1 className="section-header pt-4">Built for Mobility</h1>
                <div className="card border-0 bg-transparent">
                    <div className="card-body mobility-card">
                        <ul>
                            <li>
                                Supply chain management is in your control, manage as much or as little as you care to
                            </li>
                            <li>
                                Incredibly detailed reporting for both clients and supplier partners
                            </li>
                            <li>
                                Booking trends available for viewing instantly, by market, supplier or client
                            </li>
                            <li>
                                Robust referral fee tracking, never miss out on any revenue
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default mobility;