import React from 'react';
import GavenImage from '../../../assets/images/team/gaven.jpg';
import KarlImage from '../../../assets/images/team/karl.jpg';
import HeatherImage from '../../../assets/images/team/heather.jpg';
import FrankImage from '../../../assets/images/team/5.png';
import AhliaImage from '../../../assets/images/team/ahlia_williams.png';
import SarahImage from '../../../assets/images/team/SarahHeadShot.png';
import BrianImage from '../../../assets/images/team/Brian.png';
import MattImage from '../../../assets/images/team/Mattm.png';
import DavidImage from '../../../assets/images/team/david.png';

const team =(props)=> {
    return (
        <section id="team" className="team">
        <React.Fragment>
            <h1 className="section-header">The Team</h1>
            <div className="container team-container">
                <div className="row">
                    <div className="col text-center">
                        <div><img className="team-image" src={GavenImage} alt="Avatar"/></div>
                        <b>Gaven James C.C.H.P</b>
                        <p>President and Chief Executive Officer</p>
                    </div>
                    <div className="col text-center">
                        <div><img className="team-image" src={KarlImage} alt="Avatar"/></div>
                        <b>Karl Thuge C.R.P</b>
                        <p>President</p>
                    </div>
                    <div className="col text-center">
                        <div><img className="team-image" src={HeatherImage} alt="Avatar"/></div>
                        <b>Heather James,  C.R.P</b>
                        <p>Executive Vice President/Partner</p>
                    </div>
                    <div className="col text-center">
                        <div><img className="team-image" src={FrankImage} alt="Avatar"/></div>
                        <b>Frank Peralta</b>
                        <p>Senior Vice President of Global Supply Chain</p>
                    </div>
                    <div className="col text-center">
                        <div><img className="team-image" src={BrianImage} alt="Avatar"/></div>
                        <b>Brian Egan</b>
                        <p>Managing Director of EMEA  &amp; APAC</p>
                    </div>
                </div>
                <div className="row">
                <div className="col text-center">
                        <div><img className="team-image" src={AhliaImage} alt="Avatar"/></div>
                        <b>Ahlia Williams</b>
                        <p>Director of Reservations</p>
                    </div>
                    <div className="col text-center">
                        <div><img className="team-image" src={SarahImage} alt="Avatar"/></div>
                        <b>Sarah Simpson</b>
                        <p>Director, Product Development / IT</p>
                    </div>
                    <div className="col text-center">
                        <div><img className="team-image" src={MattImage} alt="Avatar"/></div>
                        <b>Matt McBride</b>
                        <p>Director of Quality Assurance</p>
                    </div>
                    <div className="col text-center">
                        <div><img className="team-image" src={DavidImage} alt="Avatar"/></div>
                        <b>David Janiszeski</b>
                        <p>SVP Operations - Americas</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </section>
    );
}

export default team;
