import React from 'react';
import Header from '../../../components/ui/header/Header';
import SubHeader from '../../../components/ui/subheader/Subheader';
import HousingContent from '../../sections/housingContent/housingContent';

const Housing =(props)=> {
    return (
        <React.Fragment>
            <Header title="Housing Suppliers"></Header>
            <SubHeader 
                text="Nomad Direct facilitates your access to corporate and relocation clients - 24/7. Once you become a certified partner, you are in level competition with the other partners in the markets you serve. Nomad never competes with you for inventory, and 100% of our rentals go to you and our other partners.">
            </SubHeader>
            <HousingContent windowSize={props.windowSize}></HousingContent>
        </React.Fragment>
    );
}

export default Housing;