import React, { Component } from 'react';
import NomadModel from '../../../components/ui/modal/Modal';

class Email extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState( prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        return(
            <section id="email">
                <NomadModel 
                        open={this.state.modal}
                        toggle={this.toggle} 
                        type="request" 
                >
                </NomadModel>
                <div className="container email-container">
                    <div className="row">
                        <div className="col">
                            <div className="hyper-link" onClick={this.toggle}>Request a demo or to find out more, click here.</div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Email;