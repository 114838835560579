import React from 'react';
import NewsArticle from '../../../components/ui/newsArticle/NewsArticle';
import {connect} from 'react-redux';
import * as actionTypes from '../../../store/Actions/actions';
import { withRouter } from 'react-router-dom';

class News extends React.Component {

    handleArticleClicked =(article, id)=> {
        this.props.onArticleSelected( id, article );
        var url = article.title.replace(/\s/gi, "_"); 
        this.props.history.push({ pathname: "/article/" + url });
    }

    render() {
        const content = this.props.articles.map((article, index) => {
            return <NewsArticle
                click={() => this.handleArticleClicked(article, article.id)}
                key={article.id}
                index={article.id}
                date={article.date} 
                title={article.title}
                content={article.blurb}
            /> 
        });

        return(
            <React.Fragment>
                <section id="news" className="news">
                    <h1 className="section-header">Nomad Direct News and Updates</h1>
                    <div className="container">
                        <div className="row">
                            {content}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        articles: state.newsReducer.articles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onArticleSelected: (key, article) => dispatch({ type: actionTypes.SELECT_ARTICLE, id:key, selectedAward:article }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)( News ));