import React, { Component } from 'react';
import { Collapse, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import * as actionTypes from '../../../store/Actions/actions';
import AwardCard from '../../../components/ui/award/AwardCard';

class Awards extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.props.onCollapseSelected( !this.props.collapse );
    }

    handleAwardClicked =(award, id)=> {
        console.log('id', id);
        this.props.onAwardSelected( id, award );
        var url = award.title.replace(/\s/gi, "_"); 
        this.props.history.push({ pathname: "/award/" + url });
    }

    render() {
        //this is hardcoded until we get to a database : then we will use proper querys to bring back rows
        //hacky garabage but we want it data driven in preperation to move to database ..
        //we will change this out soon..
        let row1 = [];
        let row2 = [];
        let row3 = [];
        let row4 = [];
        let row5 = [];
        const awardsLength = this.props.awds.length ;
        for ( let i =0; i < awardsLength; i ++ ) {
            if ( i  % awardsLength < 4 ) {
                row1.push(this.props.awds[i]);
            } else if  ( i  % awardsLength < 8 ) {
                row2.push(this.props.awds[i]);
            }
            else if  ( i  % awardsLength < 12 ) {
                row3.push(this.props.awds[i]);
            }
            else if  ( i  % awardsLength < 16 ) {
                row4.push(this.props.awds[i]);
            }
            else if  ( i  % awardsLength < 20 ) {
                row5.push(this.props.awds[i]);
            }
        }

        let awards1 = row1.map((award) =>
            <AwardCard 
                key={ award.id }
                clicked={()=>this.handleAwardClicked(award, award.id)}
                image={award.image}
                title={award.header}
                date={award.date}>
            </AwardCard>
        );

        let awards2 = row2.map((award) =>
            <AwardCard 
                key={ award.id }
                clicked={()=>this.handleAwardClicked(award, award.id)}
                image={award.image}
                title={award.header}
                date={award.date}>
            </AwardCard>
        );

        let awards3 = row3.map((award) =>
            <AwardCard 
                key={ award.id }
                clicked={()=>this.handleAwardClicked(award, award.id)}
                image={award.image}
                title={award.header}
                date={award.date}>
            </AwardCard>
        );

        let awards4 = row4.map((award) =>
            <AwardCard 
                key={ award.id }
                clicked={()=>this.handleAwardClicked(award, award.id)}
                image={award.image}
                title={award.header}
                date={award.date}>
            </AwardCard>
        );

        let awards5 = row5.map((award) =>
            <AwardCard 
                key={ award.id }
                clicked={()=>this.handleAwardClicked(award, award.id)}
                image={award.image}
                title={award.header}
                date={award.date}>
            </AwardCard>
        );

        const buttonString = ( !this.props.collapse ) ? 'View More Awards' : 'View Less Awards';

        return (
            <section id="awards" className="awards">
                <h1 className="section-header">Awards</h1>
                <React.Fragment>
                    <div className="container">
                        <div className="card-group">
                            {awards1}
                        </div>
                        <div className="card-group">
                            {awards2}
                        </div>
                        <Collapse isOpen={this.props.collapse}>
                            <div className="card-group">
                                {awards3}
                            </div>
                            <div className="card-group">
                                {awards4}
                            </div>
                            <div className="card-group">
                                {awards5}
                            </div>
                        </Collapse>
                        <Button color="primary mt-4" onClick={this.toggle} style={{ marginBottom: '1rem' }}>{buttonString}</Button>
                        </div>
                </React.Fragment>
                </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        awds: state.awardsReducer.awards,
        collapse: state.awardsReducer.collapse
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAwardSelected: (id, award) => dispatch({ type: actionTypes.SELECT_AWARD, id:id, selectedAward:award }),
        onCollapseSelected: (collapse) => dispatch({ type: actionTypes.COLLAPSE_AWARDS, collapse:collapse }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)( Awards ));
