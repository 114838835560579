import * as actionTypes from '../Actions/actions';

const initialState = {
    articles: [
        { 
            id: 0,
            title: "Nomad Launches Nomad Express",
            date: "November 1, 2018",
            blurb: "Nomad Express, embedded into the Nomad Direct sourcing platform, is an API connection that allows for instant access to certified partner inventory via exclusive arrangement with OSCAR tenancy management system - used for over 80% of North American inventory. Seamless sourcing for suppliers; with great ease of response for partner staff. Greatly speeds option response, yet allows partner modification when needed. Allows curated, filtered and negotiated housing options to be presented to consultants and transferees in 30 minutes or less.",
            content: "Nomad Express, embedded into the Nomad Direct sourcing platform, is an API connection that allows for instant access to certified partner inventory via exclusive arrangement with OSCAR tenancy management system - used for over 80% of North American inventory. Seamless sourcing for suppliers; with great ease of response for partner staff. Greatly speeds option response, yet allows partner modification when needed. Allows curated, filtered and negotiated housing options to be presented to consultants and transferees in 30 minutes or less."
        },
        { 
            id: 1,
            title: "Nomad Direct 250+ Partners Live",
            date: "October 20, 2018",
            blurb: "Nomad Direct is already live with most of the larger temporary housing companies in North America. The EMEA and APAC regions are in training, and adding new partners every day.  Suppliers report Nomad Direct is the easily the most efficient and most proficient platform they have worked with to date",
            content: "Nomad Direct is already live with most of the larger temporary housing companies in North America. The EMEA and APAC regions are in training, and adding new partners every day.  Suppliers report Nomad Direct is the easily the most efficient and most proficient platform they have worked with to date."
        },
        { 
            id: 2,
            title: "Nomad Direct Ease of Sending Options",
            date: "October 12, 2018",
            blurb: "Nomad has built over 15,000 property profiles for all major markets and many remote markets-they are already in our system.  This eases the burden for your staff, and allows quick and efficient responses. Chances are, most of your properties are already loaded. This makes getting onboard simple.",
            content: "Nomad has built over 15,000 property profiles for all major markets and many remote markets-they are already in our system.  This eases the burden for your staff, and allows quick and efficient responses. Chances are, most of your properties are already loaded. This makes getting onboard simple."
        },
        { 
            id: 3,
            title: "Nomad Direct and GDPR",
            date: "September 15, 2018",
            blurb: "Data security and compliance are of utmost importance to Nomad.  All Nomad Certified Partners must comply with GDPR and other global privacy data requirements. Please review our latest service level agreements for more information.",
            content: "Data security and compliance are of utmost importance to Nomad.  All Nomad Certified Partners must comply with GDPR and other global privacy data requirements. Please review our latest service level agreements for more information."
        },
        
    ],
    selectedArticle: 0
}

const reducer = ( state = initialState , action ) => {
    switch( action.type ) {
        case actionTypes.SELECT_ARTICLE:
            console.log('actionTypes.SELECT_ARTICLE')
            return {
                ...state,
                selectedArticle: action.id
            }
        default :
            return state;
    }
}

export default reducer;