import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import './App.css';
import Navigation from './components/ui/navigation/Navigation';
import Footer from './containers/sections/footer/Footer';
import EmailSection from './containers/sections/email/Email';

import Home from "./containers/pages/home/Home";
import Housing from './containers/pages/housing/Housing';
import About from './containers/pages/about/About';
import Award from './containers/pages/awards/Awards';
import News from './containers/pages/news/News';

class App extends Component {
    
    state = {
        navCollapsed: true,
        windowSize: 0,
    }

    componentWillMount() {
        this.setState ( { windowSize: window.innerWidth } )
    }

    componentDidMount() {
        this.updatewindowSize();
        window.addEventListener("resize", this.updatewindowSize);
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.updatewindowSize);
    }

    updatewindowSize = () => {
        this.setState ( { windowSize: window.innerWidth } )
    }
    
    render() {
        return (
        <React.Fragment>
            <Navigation windowSize={this.state.windowSize}></Navigation>
            <Switch>
                <Route path="/" exact render={(props) => <Home {...props} windowSize={this.state.windowSize} />} />
                <Route path="/home" exact render={(props) => <Home {...props} windowSize={this.state.windowSize} />} />
                <Route path="/about" exact render={(props) => <About {...props} windowSize={this.state.windowSize} />} />
                <Route path="/housing" exact render={(props) => <Housing {...props} windowSize={this.state.windowSize} />}  />
                <Route path="/award/:id" exact component={Award} />
                <Route path="/article/:id" exact component={News} />
            </Switch>
            <EmailSection></EmailSection>
            <Footer windowSize={this.state.windowSize} />
        </React.Fragment>
        );
    }
}
export default App;
