import React from 'react';

const header =(props)=> {
    return(
        <React.Fragment>
            <section id="banner" className="header-banner">
                <div className="container">
                    <div>
                        <div className="header-title">{props.title}</div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default header;