import React from 'react';
import { Component } from 'react';
import Logo from '../../../assets/images/NomadDirectLogo.png';
import NomadModel from '../../../components/ui/modal/Modal';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState( prevState => ({
            modal: !prevState.modal
        }));
    }

    onPhoneClicked =()=> {
        window.open('tel:619-313-4300', "_blank");
    }

    onLoginClicked =()=> {
        window.open('https://my.nomaddirect.com/login', "_blank");
    }

    onVisitUsClicked =()=> {
        window.open('https://nomadtemphousing.com', "_blank");
    }

    onEmailClicked =()=> {
        window.location.assign('mailto:info@nomadtemphousing.com')
    }

    render() {

        const content = ( this.props.windowSize > 1200 ) 
        ?   <div className="container-fluid">
            <div className="row">
                <div className="col footer-links"> 
                    <div className="footer-link" onClick={this.toggle}>CONTACT US</div>
                    <div><a href="https://my.nomaddirect.com/login" target="_blank" rel="noopener noreferrer" className="footer-link">USER LOGIN</a></div>
                    <div><a href="https://nomadtemphousing.com" target="_blank" rel="noopener noreferrer" className="footer-link">Visit NomadTempHousing.com</a></div>
                </div>
                <div className="col footer-logo"><img src={Logo} alt="nomad direct website logo"/></div>
                <div className="col footer-links-right"> 
                    <div className="footer-phone" onClick={this.onPhoneClicked}>(619) 313-4300</div>
                    <div className="footer-email-link" onClick={this.onEmailClicked}>info@nomadtemphousing.com</div>
                    <p className="footer-link">16835 W Bernardo Dr #100
                        <br/>San Diego, CA 92127
                    </p>
                </div>
            </div>
            </div>
        :   <div className="container-fluid">
                <img className="pt-4" src={Logo} alt="nomad direct website logo"/>
                <div className="footer-link" onClick={this.toggle}>CONTACT US</div>
                <div className="footer-link" onClick={this.onLoginClicked}>USER LOGIN</div>
                <div className="footer-link" onClick={this.onVisitUsClicked}>Visit NomadTempHousing.com</div>
                <div className="footer-phone" onClick={this.onPhoneClicked}>(619) 313-4300</div>
                <div className="footer-email-link" onClick={this.onEmailClicked}>info@nomadtemphousing.com</div>
                <p className="footer-address">16835 W Bernardo Dr #100 San Diego, CA 92127</p>
            </div>


        return (
            <section id="footer" className="footer">
                <NomadModel 
                    open={this.state.modal}
                    toggle={this.toggle} 
                    type="contact" 
                />
                {content}
            </section>
        );
    }
}

export default Footer;